
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [_createElement('div', {
            'className': 'cm_FacetPanel',
            'key': '420'
        }, [this.FilterChips(function () {
                return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                            'key': `${ this.field }|${ i }`
                                        }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                            'viewBox': '0 0 24 24',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '526'
                                        }, _createElement('path', {
                                            'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                            'fill': 'currentColor',
                                            'stroke': 'currentColor',
                                            'strokeWidth': '0.872727',
                                            'strokeMiterlimit': '10',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                            'viewBox': '0 0 24 24',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '1057'
                                        }, _createElement('path', {
                                            'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                            'stroke': 'currentColor',
                                            'strokeWidth': '0.872727',
                                            'strokeMiterlimit': '10',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        }), _createElement('path', {
                                            'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                            'fill': 'currentColor',
                                            'stroke': 'currentColor',
                                            'strokeWidth': '0.872727',
                                            'strokeMiterlimit': '10',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                            'viewBox': '0 0 24 24',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '1796'
                                        }, _createElement('path', {
                                            'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                            'stroke': 'currentColor',
                                            'strokeWidth': '0.872727',
                                            'strokeMiterlimit': '10',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        })) : null);
                                    }
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '), [_createElement('div', {
                                            'className': 'product-rating',
                                            'data-rating-style': 'regular',
                                            'key': '1510'
                                        }, _createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'star-rating product-rating__stars',
                                                'data-rating-style': 'regular'
                                            },
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI1_reviewChip.bind(this))
                                        ]))], this.value !== '5' ? _createElement('span', { 'key': '2344' }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '24430'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2640'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn btn btn--primary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.Facets(function () {
                return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), !this.isCustomRange ? [
                                                    _createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '1541'
                                                    }, _createElement('span', {}, this.value)),
                                                    _createElement('div', {
                                                        'className': 'facetentryval',
                                                        'key': '1543'
                                                    }, this.hitCount)
                                                ] : null, this.isCustomRange ? [_createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '3901'
                                                    }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeDollarInchMm4() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '13811'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Min',
                                                                'className': 'cm_inputMin'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '334'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Max',
                                                                'className': 'cm_inputMax'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn btn btn--secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function repeatI1(i, iIndex) {
                                                    return _createElement('span', {
                                                        'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                                        'key': `${ this.field }|${ i }`
                                                    }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                        'viewBox': '0 0 24 24',
                                                        'fill': 'none',
                                                        'xmlns': 'http://www.w3.org/2000/svg',
                                                        'key': '585'
                                                    }, _createElement('path', {
                                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'fill': 'currentColor',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                        'viewBox': '0 0 24 24',
                                                        'fill': 'none',
                                                        'xmlns': 'http://www.w3.org/2000/svg',
                                                        'key': '1116'
                                                    }, _createElement('path', {
                                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    }), _createElement('path', {
                                                        'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'fill': 'currentColor',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                        'viewBox': '0 0 24 24',
                                                        'fill': 'none',
                                                        'xmlns': 'http://www.w3.org/2000/svg',
                                                        'key': '1855'
                                                    }, _createElement('path', {
                                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    })) : null);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, [_createElement('div', {
                                                        'className': 'product-rating',
                                                        'data-rating-style': 'regular',
                                                        'key': '2100'
                                                    }, _createElement.apply(this, [
                                                        'div',
                                                        {
                                                            'className': 'star-rating product-rating__stars',
                                                            'data-rating-style': 'regular'
                                                        },
                                                        _map([
                                                            0,
                                                            1,
                                                            2,
                                                            3,
                                                            4
                                                        ], repeatI1.bind(this))
                                                    ]))], this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '2407'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function repeatI1(i, iIndex) {
                                                    return _createElement('span', {
                                                        'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                                        'key': `${ this.field }|${ i }`
                                                    }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                        'viewBox': '0 0 24 24',
                                                        'fill': 'none',
                                                        'xmlns': 'http://www.w3.org/2000/svg',
                                                        'key': '588'
                                                    }, _createElement('path', {
                                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'fill': 'currentColor',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                        'viewBox': '0 0 24 24',
                                                        'fill': 'none',
                                                        'xmlns': 'http://www.w3.org/2000/svg',
                                                        'key': '1119'
                                                    }, _createElement('path', {
                                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    }), _createElement('path', {
                                                        'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'fill': 'currentColor',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                        'viewBox': '0 0 24 24',
                                                        'fill': 'none',
                                                        'xmlns': 'http://www.w3.org/2000/svg',
                                                        'key': '1858'
                                                    }, _createElement('path', {
                                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                        'stroke': 'currentColor',
                                                        'strokeWidth': '0.872727',
                                                        'strokeMiterlimit': '10',
                                                        'strokeLinecap': 'square',
                                                        'strokeLinejoin': 'round'
                                                    })) : null);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }), [_createElement('div', {
                                                        'className': 'product-rating',
                                                        'data-rating-style': 'regular',
                                                        'key': '2130'
                                                    }, _createElement.apply(this, [
                                                        'div',
                                                        {
                                                            'className': 'star-rating product-rating__stars',
                                                            'data-rating-style': 'regular'
                                                        },
                                                        _map([
                                                            0,
                                                            1,
                                                            2,
                                                            3,
                                                            4
                                                        ], repeatI1.bind(this))
                                                    ]))], _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0'
                                    }, _createElement('span', {}, this.name), [this.tooltip(function () {
                                            return _createElement('span', { 'className': 'cm_tooltip' }, _createElement('span', { 'className': 'icon' }, _createElement('svg', {
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'viewBox': '0 0 512 512'
                                            }, _createElement('path', { 'd': 'M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z' }))));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '13040'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '16250'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'role': 'list'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '2145'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '2231'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '2551'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '27201'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '4551'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '4877'
                                    }, [this.select(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10771'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10774'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                            }
                                            function repeatEntry5(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3401'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3603'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '62'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.title
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'aria-label': this.title,
                                                    'key': '2711'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '10882'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '109661' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '109663'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '112301'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn btn btn--secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '15683'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_thicknessFacet',
                                        'key': '24642'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], '" to ', this.selectedRange[1], '"\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' })))) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_advancedColorFacet',
                                        'key': '25057'
                                    }, _createElement('div', { 'className': 'base-palette' }, this.paletteItems), _createElement('div', { 'className': 'custom-colors-selector' }, _createElement('div', {
                                        'className': 'cm_button btn btn--primary',
                                        'onClick': this.openSimplePaletteDialog
                                    }, _createElement('span', { 'className': 'icon' }, [_createElement('svg', {
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'viewBox': '0 0 512 512',
                                            'key': '253530'
                                        }, _createElement('path', { 'd': 'M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' }))]), '\n    Pick your colors\n  '), _createElement('div', {
                                        'className': 'cm_button btn btn--primary',
                                        'onClick': this.openColorExtractorDialog
                                    }, _createElement('span', { 'className': 'icon' }, [_createElement('svg', {
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'viewBox': '0 0 640 512',
                                            'key': '261650'
                                        }, _createElement('path', { 'd': 'M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z' }))]), '\n    Upload Your Color\n  ')), _createElement('div', { 'className': 'cm_color-sliders' }, this.sliders)) : null, this.template === 'sizeFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_sizeFacet',
                                        'key': '26991'
                                    }, [this.WidthFacet(function () {
                                            return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '142' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '235' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], [this.LengthFacet(function () {
                                            return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '143' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '236' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })])], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', {
        'id': 'itemsBlock',
        'data-collection-columns': '4',
        'data-item-layout': 'grid'
    }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'btn btn--primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '620'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8030'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('div', { 'className': 'filter-topbar__sort-toggle btn' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n        ', this.sortSelect, '\n        ', _createElement('span', { 'className': 'cm_sort-chevron icon icon-chevron-small' }, _createElement('svg', {
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg'
            }, _createElement('path', {
                'd': 'M1.875 7.438 12 17.563 22.125 7.438',
                'stroke': 'currentColor',
                'strokeWidth': '2.75'
            }))))));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function repeatI1(variant_id, i, iIndex) {
                            return _createElement('span', {
                                'className': 'icon icon-star-' + (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled'),
                                'key': i
                            }, 'filled' === (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '8033'
                            }, _createElement('path', {
                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                'fill': 'currentColor',
                                'stroke': 'currentColor',
                                'strokeWidth': '0.872727',
                                'strokeMiterlimit': '10',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })) : null, 'half' === (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '8582'
                            }, _createElement('path', {
                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                'stroke': 'currentColor',
                                'strokeWidth': '0.872727',
                                'strokeMiterlimit': '10',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                'fill': 'currentColor',
                                'stroke': 'currentColor',
                                'strokeWidth': '0.872727',
                                'strokeMiterlimit': '10',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })) : null, 'empty' === (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '9339'
                            }, _createElement('path', {
                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                'stroke': 'currentColor',
                                'strokeWidth': '0.872727',
                                'strokeMiterlimit': '10',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })) : null);
                        }
                        function scopeVariant_id2() {
                            var variant_id = this.variants && this.variants[0]?.id;
                            return _createElement('div', {
                                'className': 'product-item animation--item',
                                'data-input-item': true,
                                'data-id': this.id,
                                'data-title': this.title,
                                'data-url': this.url,
                                'data-variant-id': variant_id,
                                'data-quantity-in-cart': '0',
                                'data-product-has-single-variant': !this.variants || this.variants <= 1,
                                'data-quick-add-style': 'icon',
                                'data-product-item-alignment': 'center',
                                'data-price-position': 'below_title',
                                'data-show-inventory': 'true',
                                'data-ratings-visible': 'true'
                            }, _createElement('div', { 'className': 'product-item__inner' }, _createElement('div', { 'className': 'product-item__media-wrapper' }, _createElement('div', {
                                'className': 'product-item__media',
                                'data-aspect-ratio': 'portrait'
                            }, _createElement('a', {
                                'className': 'product-item__image-link',
                                'href': this.url,
                                'aria-label': this.title
                            }, _createElement('div', { 'className': 'image product-item__image product-item__image--one aspect-ratio--portrait animation--lazy-load loaded' }, _createElement('img', {
                                'alt': this.title + ' Image',
                                'className': 'image__img',
                                'fetchpriority': 'high',
                                'src': this.imageOrDefault(this.resizeImage(this.image))
                            })), this.image2 ? _createElement('div', {
                                'className': 'image product-item__image product-item__image--two aspect-ratio--portrait loaded',
                                'key': '1013'
                            }, _createElement('img', {
                                'alt': this.title,
                                'className': 'image__img',
                                'loading': 'lazy',
                                'src': this.imageOrDefault(this.resizeImage(this.image2))
                            })) : null), _createElement('div', {
                                'className': 'product-badges',
                                'data-badge-shape': 'rounded-rectangle',
                                'data-badge-vertical-position': 'top',
                                'data-badge-horizontal-position': 'left'
                            }, this.on_sale ? _createElement('div', {
                                'className': 'product-badge',
                                'data-type': 'sale',
                                'key': '1447'
                            }, _createElement('span', {
                                'className': 'product-badge__inner badge-shape',
                                'data-badge-shape': 'rounded-rectangle'
                            }, _createElement('span', {
                                'className': 'displayed-discount-wrapper',
                                'data-hidden': 'false',
                                'data-has-discount': 'true'
                            }, _createElement('span', { 'data-badge-shape': 'rounded-rectangle' }, 'Sale')))) : null, (Date.now() - new Date(this.published_at)) / (1000 * 60 * 60 * 24) < 50 ? _createElement('div', {
                                'className': 'product-badge',
                                'data-type': 'new',
                                'key': '1851'
                            }, _createElement('span', {
                                'className': 'product-badge__inner badge-shape',
                                'data-badge-shape': 'rounded-rectangle'
                            }, 'New')) : null, this.out_of_stock ? _createElement('div', {
                                'className': 'product-badge',
                                'data-type': 'sold-out',
                                'key': '2112'
                            }, _createElement('span', {
                                'className': 'product-badge__inner badge-shape',
                                'data-badge-shape': 'rounded-rectangle'
                            }, '\n              Sold out\n            ')) : null), _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'grid' }, _createElement('div', { 'className': 'product-item__floating-action-buttons' }, _createElement('div', {
                                'className': 'product-item__quick-add-wrapper',
                                'data-has-variants': !!this.variants,
                                'data-quantity-in-cart': '0',
                                'data-product-id': this.id,
                                'data-quick-add-style': 'icon'
                            }, _createElement('button', {
                                'type': 'button',
                                'className': 'product-item__quick-add btn btn--rounded product-item__disc-button shows-dynamic-count',
                                'data-quantity-in-cart': '0',
                                'data-variant-id': variant_id,
                                'aria-label': 'Add to Cart'
                            }, _createElement('span', { 'className': 'product-item__quick-add__add-text' }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            })))), _createElement('span', { 'className': 'product-item__quick-add__cart-count' }, _createElement('span', {
                                'className': 'quantity-in-cart-display',
                                'data-id': this.id
                            }, '0'))), _createElement('div', { 'className': 'quantity-input-wrapper__labels product__label visually-hidden' }, _createElement('label', {
                                'className': 'quantity-input-wrapper__label fs-body-100',
                                'htmlFor': 'quantity-input-quick-add-product-item-template--' + variant_id + '__product-grid-' + this.id
                            }, '\n                  Quantity\n                ')), _createElement('div', {
                                'className': 'quantity-input-wrapper quantity-input-wrapper--product-item-quick-add',
                                'data-quantity-selector': true
                            }, _createElement('div', {
                                'className': 'quantity-input',
                                'data-bordered': 'false'
                            }, _createElement('button', {
                                'type': 'button',
                                'className': 'quantity-input__button btn btn--rounded',
                                'data-subtract-quantity': true,
                                'aria-label': 'Subtract product quantity'
                            }, _createElement('span', { 'className': 'icon icon-minus-16' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M20 12H12H4',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            })))), _createElement('button', {
                                'type': 'button',
                                'className': 'quantity-input__button btn btn--rounded',
                                'data-remove-item': true,
                                'aria-label': 'Remove'
                            }, _createElement('span', { 'className': 'icon icon-remove' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'stroke': 'currentColor',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '1.472',
                                'd': 'M1.902 5.292h20.196M19.854 5.292V21a2.244 2.244 0 0 1-2.244 2.244H6.39A2.244 2.244 0 0 1 4.146 21V5.292m3.366 0V3.048A2.244 2.244 0 0 1 9.756.804h4.488a2.244 2.244 0 0 1 2.244 2.244v2.244'
                            })))), _createElement('input', {
                                'type': 'number',
                                'name': 'updates[]',
                                'id': 'quantity-input-quick-add-product-item-template--' + variant_id + '__product-grid-' + this.id,
                                'defaultValue': '1',
                                'min': '1',
                                'pattern': '[0-9]*',
                                'className': 'quantity-input__input',
                                'data-quantity-input': true,
                                'data-product-quantity-input': true,
                                'aria-label': 'Product quantity'
                            }), _createElement('button', {
                                'type': 'button',
                                'className': 'quantity-input__button btn btn--rounded',
                                'data-add-quantity': true,
                                'aria-label': 'Add product quantity'
                            }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            }))))))), _createElement('button', {
                                'className': 'product-item__quick-view-button btn btn--rounded show-product-quick-view product-item__disc-button',
                                'href': this.url,
                                'title': 'Quick View',
                                'aria-label': 'Quick View'
                            }, _createElement('span', { 'className': 'icon icon-quick-view' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M12.0875 15.6805C13.9032 15.6805 15.375 14.2086 15.375 12.393C15.375 10.5773 13.9032 9.10547 12.0875 9.10547C10.2719 9.10547 8.80005 10.5773 8.80005 12.393C8.80005 14.2086 10.2719 15.6805 12.0875 15.6805Z',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.2',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M22.489 11.7954C21.3901 10.5555 17.0224 6 11.8 6C6.57759 6 2.20992 10.5555 1.11096 11.7954C1.03921 11.8827 1 11.9923 1 12.1053C1 12.2184 1.03921 12.3279 1.11096 12.4153C2.20992 13.6552 6.57759 18.2107 11.8 18.2107C17.0224 18.2107 21.3901 13.6552 22.489 12.4153C22.5608 12.3279 22.6 12.2184 22.6 12.1053C22.6 11.9923 22.5608 11.8827 22.489 11.7954V11.7954Z',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.2',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round'
                            })))))))), _createElement('div', { 'className': 'product-item__meta' }, _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'grid' }), _createElement('div', { 'className': 'product-item__text ta-c' }, _createElement('div', { 'className': 'product-item__text_group_primary' }, _createElement('h3', {
                                'className': 'product-item__product-title fs-product-title ff-heading',
                                'data-line-limit': '3',
                                'data-base-size-setting': '20'
                            }, _createElement('a', { 'href': this.url }, this.title)), [this.review_count ? _createElement('div', {
                                    'className': 'product-rating',
                                    'data-rating-style': 'regular',
                                    'key': '76430'
                                }, _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'star-rating product-rating__stars',
                                        'data-rating-style': 'regular'
                                    },
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1.bind(this, variant_id))
                                ]), this.review_count ? _createElement('div', {
                                    'className': 'product-rating__count fs-body-50',
                                    'key': '9884'
                                }, _createElement('span', {}, this.review_average.toFixed(1)), _createElement('span', {}, ' (', this.review_count, ') ')) : null) : null], _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'grid' }, _createElement('div', {
                                'className': 'product-item__price-wrap',
                                'data-position': 'below_title'
                            }, _createElement('p', {
                                'className': 'product-item__price ff-product-price',
                                'data-position': 'below_title',
                                'data-enlarged-prices': 'false'
                            }, _createElement('span', { 'className': 'product-item__price-main' }, _createElement('span', { 'className': 'visually-hidden' }, 'Regular price'), this.on_sale ? _createElement('s', {
                                'className': 't-subdued',
                                'key': '10492'
                            }, this.formatPrice(this.compare_at_price)) : null, _createElement('span', { 'className': this.on_sale ? 'sale' : '' }, this.min_price !== this.max_price ? `from ${ this.formatPrice(this.min_price) }` : this.formatPrice(this.min_price))), this.on_sale ? _createElement('span', {
                                'className': 'displayed-discount-wrapper',
                                'data-hidden': 'false',
                                'data-has-discount': 'true',
                                'key': '10850'
                            }, _createElement('span', {
                                'className': 'displayed-discount badge-shape fs-body-60',
                                'data-badge-shape': 'rounded-rectangle'
                            }, Math.round(this.calcDiscount(this.min_price, this.compare_at_price)), '% off')) : null), _createElement('dl', {
                                'className': 'unit-price',
                                'data-unit-price-container': true,
                                'data-unit-price-available': 'false'
                            }, _createElement('dt', {}, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Unit price')), _createElement('dd', { 'className': 'unit-price__price' }, _createElement('span', { 'data-unit-price': true }), _createElement('span', { 'aria-hidden': 'true' }, '/'), _createElement('span', { 'className': 'visually-hidden' }, 'per'), _createElement('span', { 'data-unit-base': true }, _createElement('span', { 'data-unit-price-base-unit': true })))), _createElement('p', {})))), _createElement('div', { 'className': 'product-item__text_group_secondary' }, _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'list' }, _createElement('div', {
                                'className': 'product-item__price-wrap',
                                'data-position': 'below_title'
                            }, _createElement('p', {
                                'className': 'product-item__price ff-product-price',
                                'data-position': 'below_title',
                                'data-enlarged-prices': 'false'
                            }, _createElement('span', { 'className': 'product-item__price-main' }, _createElement('span', { 'className': 'visually-hidden' }, 'Regular price'), this.on_sale ? _createElement('s', {
                                'className': 't-subdued',
                                'key': '12344'
                            }, this.formatPrice(this.compare_at_price)) : null, _createElement('span', { 'className': this.on_sale ? 'sale' : '' }, this.min_price !== this.max_price ? `from ${ this.formatPrice(this.min_price) }` : this.formatPrice(this.min_price))), _createElement('span', {
                                'className': 'displayed-discount-wrapper',
                                'data-hidden': 'false',
                                'data-has-discount': 'true'
                            }, _createElement('span', {
                                'className': 'displayed-discount badge-shape fs-body-60',
                                'data-badge-shape': 'rounded-rectangle'
                            }, Math.round(this.calcDiscount(this.min_price, this.compare_at_price)), '% off'))), _createElement('dl', {
                                'className': 'unit-price',
                                'data-unit-price-container': true,
                                'data-unit-price-available': 'false'
                            }, _createElement('dt', {}, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Unit price')), _createElement('dd', { 'className': 'unit-price__price' }, _createElement('span', { 'data-unit-price': true }), _createElement('span', { 'aria-hidden': 'true' }, '/'), _createElement('span', { 'className': 'visually-hidden' }, 'per'), _createElement('span', { 'data-unit-base': true }, _createElement('span', { 'data-unit-price-base-unit': true })))), _createElement('p', {}))), _createElement('p', {
                                'className': 'product-item__stock-indicator fs-body-75',
                                'data-in-stock': !this.out_of_stock
                            }, _createElement('span', { 'className': 'product-item__stock-indicator-dot' }), '\n            ', this.out_of_stock ? 'Out of stock' : 'In stock', '\n          '), _createElement('a', {
                                'className': 'cm_related-products-link',
                                'href': window.Convermax.getSearchLink(this),
                                'target': '_blank'
                            }, '\n            More Like This ', _createElement('span', { 'className': 'arrow' }, '\u25B6')), _createElement('div', {
                                'className': 'product-item__list-mode-buttons-wrap',
                                'data-product-item-element-visible-only-for-mode': 'list'
                            }, _createElement('div', { 'className': 'product-item__list-mode-buttons' }, _createElement('button', {
                                'className': 'product-item__quick-view-button btn btn--rounded show-product-quick-view product-item__disc-button',
                                'href': this.url,
                                'title': 'Quick View',
                                'aria-label': 'Quick View'
                            }, _createElement('span', { 'className': 'icon icon-quick-view' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M12.0875 15.6805C13.9032 15.6805 15.375 14.2086 15.375 12.393C15.375 10.5773 13.9032 9.10547 12.0875 9.10547C10.2719 9.10547 8.80005 10.5773 8.80005 12.393C8.80005 14.2086 10.2719 15.6805 12.0875 15.6805Z',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.2',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M22.489 11.7954C21.3901 10.5555 17.0224 6 11.8 6C6.57759 6 2.20992 10.5555 1.11096 11.7954C1.03921 11.8827 1 11.9923 1 12.1053C1 12.2184 1.03921 12.3279 1.11096 12.4153C2.20992 13.6552 6.57759 18.2107 11.8 18.2107C17.0224 18.2107 21.3901 13.6552 22.489 12.4153C22.5608 12.3279 22.6 12.2184 22.6 12.1053C22.6 11.9923 22.5608 11.8827 22.489 11.7954V11.7954Z',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.2',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round'
                            })))), _createElement('div', {
                                'className': 'product-item__quick-add-wrapper',
                                'data-has-variants': !!this.variants,
                                'data-quantity-in-cart': '0',
                                'data-product-id': this.id,
                                'data-quick-add-style': 'icon'
                            }, _createElement('button', {
                                'type': 'button',
                                'className': 'product-item__quick-add btn btn--rounded product-item__disc-button shows-dynamic-count',
                                'data-quantity-in-cart': '0',
                                'data-variant-id': variant_id,
                                'aria-label': 'Add to Cart'
                            }, _createElement('span', { 'className': 'product-item__quick-add__add-text' }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            })))), _createElement('span', { 'className': 'product-item__quick-add__cart-count' }, _createElement('span', {
                                'className': 'quantity-in-cart-display',
                                'data-id': this.id
                            }, '0'))), _createElement('div', { 'className': 'quantity-input-wrapper__labels product__label visually-hidden' }, _createElement('label', {
                                'className': 'quantity-input-wrapper__label fs-body-100',
                                'htmlFor': 'quantity-input-quick-add-list-mode-product-item-template--' + variant_id + '__product-grid-' + this.id
                            }, '\n                    Quantity\n                  ')), _createElement('div', {
                                'className': 'quantity-input-wrapper quantity-input-wrapper--product-item-quick-add',
                                'data-quantity-selector': true
                            }, _createElement('div', {
                                'className': 'quantity-input',
                                'data-bordered': 'false'
                            }, _createElement('button', {
                                'type': 'button',
                                'className': 'quantity-input__button btn btn--rounded',
                                'data-subtract-quantity': true,
                                'aria-label': 'Subtract product quantity'
                            }, _createElement('span', { 'className': 'icon icon-minus-16' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M20 12H12H4',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            })))), _createElement('button', {
                                'type': 'button',
                                'className': 'quantity-input__button btn btn--rounded',
                                'data-remove-item': true,
                                'aria-label': 'Remove'
                            }, _createElement('span', { 'className': 'icon icon-remove' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'stroke': 'currentColor',
                                'strokeLinecap': 'round',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '1.472',
                                'd': 'M1.902 5.292h20.196M19.854 5.292V21a2.244 2.244 0 0 1-2.244 2.244H6.39A2.244 2.244 0 0 1 4.146 21V5.292m3.366 0V3.048A2.244 2.244 0 0 1 9.756.804h4.488a2.244 2.244 0 0 1 2.244 2.244v2.244'
                            })))), _createElement('input', {
                                'type': 'number',
                                'name': 'updates[]',
                                'id': 'quantity-input-quick-add-list-mode-product-item-template--17283397353672__product-grid-9777121222',
                                'defaultValue': '1',
                                'min': '1',
                                'pattern': '[0-9]*',
                                'className': 'quantity-input__input',
                                'data-quantity-input': true,
                                'data-product-quantity-input': true,
                                'aria-label': 'Product quantity'
                            }), _createElement('button', {
                                'type': 'button',
                                'className': 'quantity-input__button btn btn--rounded',
                                'data-add-quantity': true,
                                'aria-label': 'Add product quantity'
                            }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                                'viewBox': '0 0 24 24',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                                'stroke': 'currentColor',
                                'strokeWidth': '1.25'
                            }))))))))))))));
                        }
                        return scopeVariant_id2.apply(this, []);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                {
                    'className': 'collection__products collection__infinite-container standard-grid-spacing product-item-holder' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items',
                    'data-standard-grid-spacing-type': 'product-list'
                },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse btn btn--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('p', { 'className': 'pagination__footer fs-body-75' }, '\n  You\'re viewing ', this.pageTo - this.pageFrom + 1, ' of ', this.totalHits, ' products\n')));
        }, {
            widgetName: 'search-header',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:FilterChips","cm:tooltip","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult","cm:SearchHeader"]