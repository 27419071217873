
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(variant_id, i, iIndex) {
                    return _createElement('span', {
                        'className': 'icon icon-star-' + (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled'),
                        'key': i
                    }, 'filled' === (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'key': '8033'
                    }, _createElement('path', {
                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                        'fill': 'currentColor',
                        'stroke': 'currentColor',
                        'strokeWidth': '0.872727',
                        'strokeMiterlimit': '10',
                        'strokeLinecap': 'square',
                        'strokeLinejoin': 'round'
                    })) : null, 'half' === (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'key': '8582'
                    }, _createElement('path', {
                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                        'stroke': 'currentColor',
                        'strokeWidth': '0.872727',
                        'strokeMiterlimit': '10',
                        'strokeLinecap': 'square',
                        'strokeLinejoin': 'round'
                    }), _createElement('path', {
                        'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                        'fill': 'currentColor',
                        'stroke': 'currentColor',
                        'strokeWidth': '0.872727',
                        'strokeMiterlimit': '10',
                        'strokeLinecap': 'square',
                        'strokeLinejoin': 'round'
                    })) : null, 'empty' === (this.review_average - i <= 0 ? 'empty' : this.review_average - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'key': '9339'
                    }, _createElement('path', {
                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                        'stroke': 'currentColor',
                        'strokeWidth': '0.872727',
                        'strokeMiterlimit': '10',
                        'strokeLinecap': 'square',
                        'strokeLinejoin': 'round'
                    })) : null);
                }
                function scopeVariant_id2() {
                    var variant_id = this.variants && this.variants[0]?.id;
                    return _createElement('div', {
                        'className': 'product-item animation--item',
                        'data-input-item': true,
                        'data-id': this.id,
                        'data-title': this.title,
                        'data-url': this.url,
                        'data-variant-id': variant_id,
                        'data-quantity-in-cart': '0',
                        'data-product-has-single-variant': !this.variants || this.variants <= 1,
                        'data-quick-add-style': 'icon',
                        'data-product-item-alignment': 'center',
                        'data-price-position': 'below_title',
                        'data-show-inventory': 'true',
                        'data-ratings-visible': 'true'
                    }, _createElement('div', { 'className': 'product-item__inner' }, _createElement('div', { 'className': 'product-item__media-wrapper' }, _createElement('div', {
                        'className': 'product-item__media',
                        'data-aspect-ratio': 'portrait'
                    }, _createElement('a', {
                        'className': 'product-item__image-link',
                        'href': this.url,
                        'aria-label': this.title
                    }, _createElement('div', { 'className': 'image product-item__image product-item__image--one aspect-ratio--portrait animation--lazy-load loaded' }, _createElement('img', {
                        'alt': this.title + ' Image',
                        'className': 'image__img',
                        'fetchpriority': 'high',
                        'src': this.imageOrDefault(this.resizeImage(this.image))
                    })), this.image2 ? _createElement('div', {
                        'className': 'image product-item__image product-item__image--two aspect-ratio--portrait loaded',
                        'key': '1013'
                    }, _createElement('img', {
                        'alt': this.title,
                        'className': 'image__img',
                        'loading': 'lazy',
                        'src': this.imageOrDefault(this.resizeImage(this.image2))
                    })) : null), _createElement('div', {
                        'className': 'product-badges',
                        'data-badge-shape': 'rounded-rectangle',
                        'data-badge-vertical-position': 'top',
                        'data-badge-horizontal-position': 'left'
                    }, this.on_sale ? _createElement('div', {
                        'className': 'product-badge',
                        'data-type': 'sale',
                        'key': '1447'
                    }, _createElement('span', {
                        'className': 'product-badge__inner badge-shape',
                        'data-badge-shape': 'rounded-rectangle'
                    }, _createElement('span', {
                        'className': 'displayed-discount-wrapper',
                        'data-hidden': 'false',
                        'data-has-discount': 'true'
                    }, _createElement('span', { 'data-badge-shape': 'rounded-rectangle' }, 'Sale')))) : null, (Date.now() - new Date(this.published_at)) / (1000 * 60 * 60 * 24) < 50 ? _createElement('div', {
                        'className': 'product-badge',
                        'data-type': 'new',
                        'key': '1851'
                    }, _createElement('span', {
                        'className': 'product-badge__inner badge-shape',
                        'data-badge-shape': 'rounded-rectangle'
                    }, 'New')) : null, this.out_of_stock ? _createElement('div', {
                        'className': 'product-badge',
                        'data-type': 'sold-out',
                        'key': '2112'
                    }, _createElement('span', {
                        'className': 'product-badge__inner badge-shape',
                        'data-badge-shape': 'rounded-rectangle'
                    }, '\n              Sold out\n            ')) : null), _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'grid' }, _createElement('div', { 'className': 'product-item__floating-action-buttons' }, _createElement('div', {
                        'className': 'product-item__quick-add-wrapper',
                        'data-has-variants': !!this.variants,
                        'data-quantity-in-cart': '0',
                        'data-product-id': this.id,
                        'data-quick-add-style': 'icon'
                    }, _createElement('button', {
                        'type': 'button',
                        'className': 'product-item__quick-add btn btn--rounded product-item__disc-button shows-dynamic-count',
                        'data-quantity-in-cart': '0',
                        'data-variant-id': variant_id,
                        'aria-label': 'Add to Cart'
                    }, _createElement('span', { 'className': 'product-item__quick-add__add-text' }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    })))), _createElement('span', { 'className': 'product-item__quick-add__cart-count' }, _createElement('span', {
                        'className': 'quantity-in-cart-display',
                        'data-id': this.id
                    }, '0'))), _createElement('div', { 'className': 'quantity-input-wrapper__labels product__label visually-hidden' }, _createElement('label', {
                        'className': 'quantity-input-wrapper__label fs-body-100',
                        'htmlFor': 'quantity-input-quick-add-product-item-template--' + variant_id + '__product-grid-' + this.id
                    }, '\n                  Quantity\n                ')), _createElement('div', {
                        'className': 'quantity-input-wrapper quantity-input-wrapper--product-item-quick-add',
                        'data-quantity-selector': true
                    }, _createElement('div', {
                        'className': 'quantity-input',
                        'data-bordered': 'false'
                    }, _createElement('button', {
                        'type': 'button',
                        'className': 'quantity-input__button btn btn--rounded',
                        'data-subtract-quantity': true,
                        'aria-label': 'Subtract product quantity'
                    }, _createElement('span', { 'className': 'icon icon-minus-16' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M20 12H12H4',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    })))), _createElement('button', {
                        'type': 'button',
                        'className': 'quantity-input__button btn btn--rounded',
                        'data-remove-item': true,
                        'aria-label': 'Remove'
                    }, _createElement('span', { 'className': 'icon icon-remove' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '1.472',
                        'd': 'M1.902 5.292h20.196M19.854 5.292V21a2.244 2.244 0 0 1-2.244 2.244H6.39A2.244 2.244 0 0 1 4.146 21V5.292m3.366 0V3.048A2.244 2.244 0 0 1 9.756.804h4.488a2.244 2.244 0 0 1 2.244 2.244v2.244'
                    })))), _createElement('input', {
                        'type': 'number',
                        'name': 'updates[]',
                        'id': 'quantity-input-quick-add-product-item-template--' + variant_id + '__product-grid-' + this.id,
                        'defaultValue': '1',
                        'min': '1',
                        'pattern': '[0-9]*',
                        'className': 'quantity-input__input',
                        'data-quantity-input': true,
                        'data-product-quantity-input': true,
                        'aria-label': 'Product quantity'
                    }), _createElement('button', {
                        'type': 'button',
                        'className': 'quantity-input__button btn btn--rounded',
                        'data-add-quantity': true,
                        'aria-label': 'Add product quantity'
                    }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    }))))))), _createElement('button', {
                        'className': 'product-item__quick-view-button btn btn--rounded show-product-quick-view product-item__disc-button',
                        'href': this.url,
                        'title': 'Quick View',
                        'aria-label': 'Quick View'
                    }, _createElement('span', { 'className': 'icon icon-quick-view' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M12.0875 15.6805C13.9032 15.6805 15.375 14.2086 15.375 12.393C15.375 10.5773 13.9032 9.10547 12.0875 9.10547C10.2719 9.10547 8.80005 10.5773 8.80005 12.393C8.80005 14.2086 10.2719 15.6805 12.0875 15.6805Z',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.2',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round'
                    }), _createElement('path', {
                        'd': 'M22.489 11.7954C21.3901 10.5555 17.0224 6 11.8 6C6.57759 6 2.20992 10.5555 1.11096 11.7954C1.03921 11.8827 1 11.9923 1 12.1053C1 12.2184 1.03921 12.3279 1.11096 12.4153C2.20992 13.6552 6.57759 18.2107 11.8 18.2107C17.0224 18.2107 21.3901 13.6552 22.489 12.4153C22.5608 12.3279 22.6 12.2184 22.6 12.1053C22.6 11.9923 22.5608 11.8827 22.489 11.7954V11.7954Z',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.2',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round'
                    })))))))), _createElement('div', { 'className': 'product-item__meta' }, _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'grid' }), _createElement('div', { 'className': 'product-item__text ta-c' }, _createElement('div', { 'className': 'product-item__text_group_primary' }, _createElement('h3', {
                        'className': 'product-item__product-title fs-product-title ff-heading',
                        'data-line-limit': '3',
                        'data-base-size-setting': '20'
                    }, _createElement('a', { 'href': this.url }, this.title)), [this.review_count ? _createElement('div', {
                            'className': 'product-rating',
                            'data-rating-style': 'regular',
                            'key': '76430'
                        }, _createElement.apply(this, [
                            'div',
                            {
                                'className': 'star-rating product-rating__stars',
                                'data-rating-style': 'regular'
                            },
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI1.bind(this, variant_id))
                        ]), this.review_count ? _createElement('div', {
                            'className': 'product-rating__count fs-body-50',
                            'key': '9884'
                        }, _createElement('span', {}, this.review_average.toFixed(1)), _createElement('span', {}, ' (', this.review_count, ') ')) : null) : null], _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'grid' }, _createElement('div', {
                        'className': 'product-item__price-wrap',
                        'data-position': 'below_title'
                    }, _createElement('p', {
                        'className': 'product-item__price ff-product-price',
                        'data-position': 'below_title',
                        'data-enlarged-prices': 'false'
                    }, _createElement('span', { 'className': 'product-item__price-main' }, _createElement('span', { 'className': 'visually-hidden' }, 'Regular price'), this.on_sale ? _createElement('s', {
                        'className': 't-subdued',
                        'key': '10492'
                    }, this.formatPrice(this.compare_at_price)) : null, _createElement('span', { 'className': this.on_sale ? 'sale' : '' }, this.min_price !== this.max_price ? `from ${ this.formatPrice(this.min_price) }` : this.formatPrice(this.min_price))), this.on_sale ? _createElement('span', {
                        'className': 'displayed-discount-wrapper',
                        'data-hidden': 'false',
                        'data-has-discount': 'true',
                        'key': '10850'
                    }, _createElement('span', {
                        'className': 'displayed-discount badge-shape fs-body-60',
                        'data-badge-shape': 'rounded-rectangle'
                    }, Math.round(this.calcDiscount(this.min_price, this.compare_at_price)), '% off')) : null), _createElement('dl', {
                        'className': 'unit-price',
                        'data-unit-price-container': true,
                        'data-unit-price-available': 'false'
                    }, _createElement('dt', {}, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Unit price')), _createElement('dd', { 'className': 'unit-price__price' }, _createElement('span', { 'data-unit-price': true }), _createElement('span', { 'aria-hidden': 'true' }, '/'), _createElement('span', { 'className': 'visually-hidden' }, 'per'), _createElement('span', { 'data-unit-base': true }, _createElement('span', { 'data-unit-price-base-unit': true })))), _createElement('p', {})))), _createElement('div', { 'className': 'product-item__text_group_secondary' }, _createElement('div', { 'data-product-item-element-visible-only-for-mode': 'list' }, _createElement('div', {
                        'className': 'product-item__price-wrap',
                        'data-position': 'below_title'
                    }, _createElement('p', {
                        'className': 'product-item__price ff-product-price',
                        'data-position': 'below_title',
                        'data-enlarged-prices': 'false'
                    }, _createElement('span', { 'className': 'product-item__price-main' }, _createElement('span', { 'className': 'visually-hidden' }, 'Regular price'), this.on_sale ? _createElement('s', {
                        'className': 't-subdued',
                        'key': '12344'
                    }, this.formatPrice(this.compare_at_price)) : null, _createElement('span', { 'className': this.on_sale ? 'sale' : '' }, this.min_price !== this.max_price ? `from ${ this.formatPrice(this.min_price) }` : this.formatPrice(this.min_price))), _createElement('span', {
                        'className': 'displayed-discount-wrapper',
                        'data-hidden': 'false',
                        'data-has-discount': 'true'
                    }, _createElement('span', {
                        'className': 'displayed-discount badge-shape fs-body-60',
                        'data-badge-shape': 'rounded-rectangle'
                    }, Math.round(this.calcDiscount(this.min_price, this.compare_at_price)), '% off'))), _createElement('dl', {
                        'className': 'unit-price',
                        'data-unit-price-container': true,
                        'data-unit-price-available': 'false'
                    }, _createElement('dt', {}, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Unit price')), _createElement('dd', { 'className': 'unit-price__price' }, _createElement('span', { 'data-unit-price': true }), _createElement('span', { 'aria-hidden': 'true' }, '/'), _createElement('span', { 'className': 'visually-hidden' }, 'per'), _createElement('span', { 'data-unit-base': true }, _createElement('span', { 'data-unit-price-base-unit': true })))), _createElement('p', {}))), _createElement('p', {
                        'className': 'product-item__stock-indicator fs-body-75',
                        'data-in-stock': !this.out_of_stock
                    }, _createElement('span', { 'className': 'product-item__stock-indicator-dot' }), '\n            ', this.out_of_stock ? 'Out of stock' : 'In stock', '\n          '), _createElement('a', {
                        'className': 'cm_related-products-link',
                        'href': window.Convermax.getSearchLink(this),
                        'target': '_blank'
                    }, '\n            More Like This ', _createElement('span', { 'className': 'arrow' }, '\u25B6')), _createElement('div', {
                        'className': 'product-item__list-mode-buttons-wrap',
                        'data-product-item-element-visible-only-for-mode': 'list'
                    }, _createElement('div', { 'className': 'product-item__list-mode-buttons' }, _createElement('button', {
                        'className': 'product-item__quick-view-button btn btn--rounded show-product-quick-view product-item__disc-button',
                        'href': this.url,
                        'title': 'Quick View',
                        'aria-label': 'Quick View'
                    }, _createElement('span', { 'className': 'icon icon-quick-view' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M12.0875 15.6805C13.9032 15.6805 15.375 14.2086 15.375 12.393C15.375 10.5773 13.9032 9.10547 12.0875 9.10547C10.2719 9.10547 8.80005 10.5773 8.80005 12.393C8.80005 14.2086 10.2719 15.6805 12.0875 15.6805Z',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.2',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round'
                    }), _createElement('path', {
                        'd': 'M22.489 11.7954C21.3901 10.5555 17.0224 6 11.8 6C6.57759 6 2.20992 10.5555 1.11096 11.7954C1.03921 11.8827 1 11.9923 1 12.1053C1 12.2184 1.03921 12.3279 1.11096 12.4153C2.20992 13.6552 6.57759 18.2107 11.8 18.2107C17.0224 18.2107 21.3901 13.6552 22.489 12.4153C22.5608 12.3279 22.6 12.2184 22.6 12.1053C22.6 11.9923 22.5608 11.8827 22.489 11.7954V11.7954Z',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.2',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round'
                    })))), _createElement('div', {
                        'className': 'product-item__quick-add-wrapper',
                        'data-has-variants': !!this.variants,
                        'data-quantity-in-cart': '0',
                        'data-product-id': this.id,
                        'data-quick-add-style': 'icon'
                    }, _createElement('button', {
                        'type': 'button',
                        'className': 'product-item__quick-add btn btn--rounded product-item__disc-button shows-dynamic-count',
                        'data-quantity-in-cart': '0',
                        'data-variant-id': variant_id,
                        'aria-label': 'Add to Cart'
                    }, _createElement('span', { 'className': 'product-item__quick-add__add-text' }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    })))), _createElement('span', { 'className': 'product-item__quick-add__cart-count' }, _createElement('span', {
                        'className': 'quantity-in-cart-display',
                        'data-id': this.id
                    }, '0'))), _createElement('div', { 'className': 'quantity-input-wrapper__labels product__label visually-hidden' }, _createElement('label', {
                        'className': 'quantity-input-wrapper__label fs-body-100',
                        'htmlFor': 'quantity-input-quick-add-list-mode-product-item-template--' + variant_id + '__product-grid-' + this.id
                    }, '\n                    Quantity\n                  ')), _createElement('div', {
                        'className': 'quantity-input-wrapper quantity-input-wrapper--product-item-quick-add',
                        'data-quantity-selector': true
                    }, _createElement('div', {
                        'className': 'quantity-input',
                        'data-bordered': 'false'
                    }, _createElement('button', {
                        'type': 'button',
                        'className': 'quantity-input__button btn btn--rounded',
                        'data-subtract-quantity': true,
                        'aria-label': 'Subtract product quantity'
                    }, _createElement('span', { 'className': 'icon icon-minus-16' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M20 12H12H4',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    })))), _createElement('button', {
                        'type': 'button',
                        'className': 'quantity-input__button btn btn--rounded',
                        'data-remove-item': true,
                        'aria-label': 'Remove'
                    }, _createElement('span', { 'className': 'icon icon-remove' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '1.472',
                        'd': 'M1.902 5.292h20.196M19.854 5.292V21a2.244 2.244 0 0 1-2.244 2.244H6.39A2.244 2.244 0 0 1 4.146 21V5.292m3.366 0V3.048A2.244 2.244 0 0 1 9.756.804h4.488a2.244 2.244 0 0 1 2.244 2.244v2.244'
                    })))), _createElement('input', {
                        'type': 'number',
                        'name': 'updates[]',
                        'id': 'quantity-input-quick-add-list-mode-product-item-template--17283397353672__product-grid-9777121222',
                        'defaultValue': '1',
                        'min': '1',
                        'pattern': '[0-9]*',
                        'className': 'quantity-input__input',
                        'data-quantity-input': true,
                        'data-product-quantity-input': true,
                        'aria-label': 'Product quantity'
                    }), _createElement('button', {
                        'type': 'button',
                        'className': 'quantity-input__button btn btn--rounded',
                        'data-add-quantity': true,
                        'aria-label': 'Add product quantity'
                    }, _createElement('span', { 'className': 'icon icon-plus-16' }, _createElement('svg', {
                        'viewBox': '0 0 24 24',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg'
                    }, _createElement('path', {
                        'd': 'M12 4V12M12 12V20M12 12H20M12 12H4',
                        'stroke': 'currentColor',
                        'strokeWidth': '1.25'
                    }))))))))))))));
                }
                return scopeVariant_id2.apply(this, []);
            }, { count: undefined })];
    }
    return _createElement('div', {}, _createElement.apply(this, [
        'div',
        { 'className': 'cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]), _createElement('div', { 'className': 'cm_browse-coordinating' }, '\n    Browse all\n    ', _createElement('a', {
        'className': 'cm_related-products-link',
        'href': this.searchLink,
        'target': '_blank'
    }, 'similar rugs ', _createElement('span', { 'className': 'arrow' }, '\u25B6'))));
}
        export const componentNames = []